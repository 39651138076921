import { Form, Input, Radio } from 'antd';

const ExtraFormItem = ({ extra }) => {
  return (
    <Form.List name="extra">
      {fields => (
        <>
          {fields.map(field => {
            const i = extra[field.key];
            return (
              <Form.Item
                {...field}
                label={i['name']}
                name={[field.name, 'value']}
                rules={[
                  {
                    required: i['require'],
                  },
                ]}
              >
                {i['type'] === 'input' ? (
                  <Input></Input>
                ) : i['type'] === 'bool' ? (
                  <Radio.Group>
                    <Radio value={true} key={'true'}>
                      是
                    </Radio>
                    <Radio value={false} key={'false'}>
                      否
                    </Radio>
                  </Radio.Group>
                ) : (
                  <Radio.Group>
                    {i['options'].split(',').map(j => (
                      <Radio value={j} key={j}>
                        {j}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            );
          })}
        </>
      )}
    </Form.List>
  );
};

export default ExtraFormItem;
