import React, { createContext, useState } from 'react';
import api from '../api';

const TOKEN_KEY = 'jwt';

export const AuthContext = createContext({
  token: undefined,
  login: undefined,
  logout: undefined,
  loading: false,
});

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    window.localStorage.getItem(TOKEN_KEY)
      ? window.localStorage.getItem(TOKEN_KEY)
      : window.sessionStorage.getItem(TOKEN_KEY)
      ? window.sessionStorage.getItem(TOKEN_KEY)
      : undefined
  );
  const [loading, setLoading] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        token: token,
        loading: loading,
        logout: () => {
          window.localStorage.removeItem(TOKEN_KEY);
          window.sessionStorage.removeItem(TOKEN_KEY);
          setToken(undefined);
        },
        login: async (username, password, remember) => {
          setLoading(true);
          return api.Auth.login(username, password)
            .then(({ data }) => {
              const token = data.access_token;
              if (remember) {
                window.localStorage.setItem(TOKEN_KEY, token);
              } else {
                window.sessionStorage.setItem(TOKEN_KEY, token);
              }
              setToken(token);
            })
            .finally(() => {
              setLoading(false);
            });
        },
        resetPassword: async (queryToken, newPassword) => {
          setLoading(true);
          return api.Auth.resetPassword(queryToken, newPassword).finally(() => {
            setLoading(false);
          });
        },
        recoverPassword: async email => {
          setLoading(true);
          return api.Auth.recoverPassword(email).finally(() => {
            setLoading(false);
          });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
