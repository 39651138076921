import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { Button } from 'antd';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import DonationAnonymousForm from '../components/donation/DonationAnonymousForm';
import DonationForm from '../components/donation/DonationForm';
import DonationInfoConfirmForm from '../components/donation/DonationInfoConfirmForm';
import DonationPayForm from '../components/donation/DonationPayForm';
import { useCampaign } from '../hooks/campaign';

const DonatePage = () => {
  const history = useHistory();
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const { token } = useContext(AuthContext);
  const { selectedCampaignId } = useParams();
  const [campaign, setCampaign] = useState(undefined);
  const [donation, setDonation] = useState(undefined);
  const { campaigns } = useCampaign();

  const steps = [
    {
      label: '捐款資料',
      content: (
        <DonationForm
          token={token}
          campaign={campaign}
          setDonation={setDonation}
          nextStep={nextStep}
        />
      ),
    },
    {
      label: '基本資料',
      content: token ? (
        <DonationInfoConfirmForm
          donation={donation}
          setDonation={setDonation}
          nextStep={nextStep}
        />
      ) : (
        <DonationAnonymousForm
          donation={donation}
          setDonation={setDonation}
          nextStep={nextStep}
        />
      ),
    },
    {
      label: '捐款',
      content: <DonationPayForm donation={donation} />,
    },
  ];

  useEffect(() => {
    const campaign = campaigns.find(j => j.id === parseInt(selectedCampaignId));
    if (campaign) {
      setCampaign(campaign);
    }
  }, [selectedCampaignId, campaigns]);

  return (
    <>
      {campaign ? (
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
          <Stack
            spacing={8}
            mx={'auto'}
            maxW={'lg'}
            minW={'400px'}
            py={12}
            px={6}
          >
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>捐款</Heading>
            </Stack>
            <Stack mt={5}>
              <Steps activeStep={activeStep} colorScheme="brand">
                {steps.map(({ label, content }) => (
                  <Step label={label} key={label}>
                    <Stack spacing={10}>
                      <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
                        {content}
                      </Box>
                      <Box textAlign="center">
                        {activeStep === 0 ? (
                          <Button type="link" onClick={() => history.goBack()}>
                            上一頁
                          </Button>
                        ) : (
                          <Button type="link" onClick={prevStep}>
                            回上一步
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Step>
                ))}
              </Steps>
            </Stack>
          </Stack>
        </Flex>
      ) : null}
    </>
  );
};

export default DonatePage;
