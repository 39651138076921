import { Heading, Image, Text } from '@chakra-ui/react';
import { Skeleton } from 'antd';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import SessionTable from '../components/course/SessionTable';
import { s3Root } from '../constants';
import { useCourse } from '../hooks/course';

const CoursePage = () => {
  const { courseId } = useParams();
  const { courses } = useCourse();
  const course = courses?.filter(i => i.id === parseInt(courseId))[0];

  const sessions = course
    ? course['sessions'].map(i => {
        i['key'] = i.id;
        return i;
      })
    : [];

  return (
    <>
      {course ? (
        <>
          <Heading size="lg">{course.name}</Heading>
          {course.image_url?
          <Image mt="10"
            marginX={"auto"}
            width={1000}
            src={s3Root + course.image_url}
          ></Image> : null}
          <Heading size="md" mt="10" mb="3">
            課程介紹
          </Heading>
          <Text fontSize="md">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course.info),
              }}
            ></div>
          </Text>
          <Heading size="md" mt="10" mb="3">
            報名說明
          </Heading>
          <Text fontSize="md">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course.signup_info),
              }}
            ></div>
          </Text>
          {/* <Heading size="md" mt="10" mb="3">
            先修課程
          </Heading>
          <Text fontSize="md">
            {course.prerequisite && course.prerequisite !== ''
              ? course.prerequisite
              : '沒有先修課程限制'}
          </Text> */}
          <Heading size="md" mt="10" mb="3">
            費用
          </Heading>
          <Text fontSize="md">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course.price_info),
              }}
            ></div>
          </Text>
          <SessionTable sessions={sessions} />
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default CoursePage;
