import { Button, Form, Input } from 'antd';
import { sendInvoiceOptions } from '../../constants';
import MobileFormItem from '../MobileFormItem';
import OfficialIdFormItem from '../OfficialIdFormItem';

const DonationAnonymousForm = ({ nextStep, donation, setDonation }) => {
  const onFinish = values => {
    setDonation({ ...donation, ...values });
    nextStep();
  };

  return (
    <Form layout={'vertical'} onFinish={onFinish}>
      <Form.Item label="姓名" name="name">
        <Input></Input>
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input></Input>
      </Form.Item>
      <MobileFormItem required={false} />
      <OfficialIdFormItem
        required={donation.send_invoice === sendInvoiceOptions.e_annual}
      />
      <Button key="submit" htmlType="submit" type="primary" block>
        確認
      </Button>
    </Form>
  );
};

export default DonationAnonymousForm;
