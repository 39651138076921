import { Box, Stack } from '@chakra-ui/react';
import { Descriptions } from 'antd';
import { paymentType } from '../../constants';
import { useUser } from '../../hooks/user';
import EcpayButton from '../EcpayButton';

const DonationPayForm = ({ donation }) => {
  const { createDonation } = useUser();

  return (
    <>
      {donation ? (
        <Stack>
          <Descriptions column={1}>
            <Descriptions.Item label="捐款項目">
              {donation.campaign_name}
            </Descriptions.Item>
            <Descriptions.Item label="金額">
              {donation.amount}
            </Descriptions.Item>
            {donation.donation_type === '定期' ? (
              <>
                <Descriptions.Item label="捐款期數">
                  {donation.period}
                </Descriptions.Item>
              </>
            ) : null}
            <Descriptions.Item label="收據寄發">
              {donation.send_invoice}
            </Descriptions.Item>
            <Descriptions.Item label="收據寄發地址">
              {donation.send_invoice_address}
            </Descriptions.Item>
            <Descriptions.Item label="芳名錄">
              {donation.show_name}
            </Descriptions.Item>
          </Descriptions>
          <Box align={'center'}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justifyContent={'center'}
            >
              <EcpayButton
                record={donation}
                createRecord={createDonation}
                paymentType={paymentType.credit_card}
                paymentTypeName={'信用卡'}
              />
              {donation.donation_type === '單筆' ? (
                <>
                  <EcpayButton
                    record={donation}
                    createRecord={createDonation}
                    paymentType={paymentType.web_atm}
                    paymentTypeName={'Web ATM'}
                  />
                  <EcpayButton
                    record={donation}
                    createRecord={createDonation}
                    paymentType={paymentType.cvs}
                    paymentTypeName={'超商代碼'}
                  />
                  <EcpayButton
                    record={donation}
                    createRecord={createDonation}
                    paymentType={paymentType.atm}
                    paymentTypeName={'ATM轉帳'}
                  />
                </>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      ) : null}
    </>
  );
};

export default DonationPayForm;
