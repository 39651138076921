import { useQuery } from 'react-query';
import api from '../api';

export const useFaq = () => {
  const query = useQuery(['faq'], () => api.Faq.get());
  const faqs = query.isSuccess ? query.data.data : [];

  return {
    faqs: faqs.sort((a, b) => a.id - b.id),
  };
};
