import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function HomePage() {
  return (
    <Container maxW={'3xl'}>
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
          lineHeight={'110%'}
        >
          歡迎您使用
          <br />
          <Text as={'span'} color={'brand.600'}>
            {process.env.REACT_APP_ORGANIZATION_NAME}會員系統
          </Text>
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'md' }}>
          不論是任何關心{process.env.REACT_APP_ORGANIZATION_NAME}
          的朋友都可以在這個平台上方便的捐款、報名課程和管理個人資料，只需要在右上角註冊帳號就可以使用所有功能
        </Text>
      </Stack>
    </Container>
  );
}

export default HomePage;
