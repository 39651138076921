import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import { Skeleton } from 'antd';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { useCampaign } from '../hooks/campaign';

const CampaignPage = () => {
  const { campaignId } = useParams();
  const { campaigns } = useCampaign();
  const { token } = useContext(AuthContext);
  const campaign = campaigns?.filter(i => i.id === parseInt(campaignId))[0];
  const location = {
    pathname: token && campaign ? `/donation/create/${campaign.id}` : '/login',
    state: {
      from: {
        pathname: campaign ? `/campaign/${campaign.id}` : '/',
        to: campaign ? `/donation/create/${campaign.id}` : '/',
      },
    },
  };

  return (
    <>
      {campaign ? (
        <VStack justifyItems={'center'}>
          <Heading size="lg">{campaign.name}</Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: campaign.info,
            }}
          ></div>
          <Link to={location}>
            <Box mt={10}>
              <Button colorScheme={'brand'}>立即捐款</Button>
            </Box>
          </Link>
        </VStack>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default CampaignPage;
