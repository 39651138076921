import { Form, Input, Select } from 'antd';
import React from 'react';
import { officialType } from '../constants';

const { Option } = Select;

const OfficialIdFormItem = ({ required = false }) => {
  return (
    <Form.Item
      label="證件號碼"
      required={required}
      extra={'欲透過自然人憑證下載捐款資料/申請志工服務時數者，請務必填寫'}
    >
      <Input.Group compact>
        <Form.Item
          name={'official_type'}
          noStyle
          rules={[
            {
              required: required ? true : false,
              message: '請選擇類別',
            },
          ]}
        >
          <Select style={{ width: '30%' }}>
            {officialType.map(i => (
              <Option value={i} key={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'official_id'}
          noStyle
          rules={[
            {
              required: required ? true : false,
              message: '請填寫',
            },
          ]}
        >
          <Input style={{ width: '70%' }} placeholder="證件號碼" />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

export default OfficialIdFormItem;
