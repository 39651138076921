import { Box } from '@chakra-ui/react';
import { Col, Menu, Row } from 'antd';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import ProfileContent from '../components/user/ProfileContent';
import UserInfo from '../components/user/UserInfo';
import { useMe } from '../hooks/me';

const ProfilePage = () => {
  const { user } = useMe();
  const match = useRouteMatch();
  const location = useLocation();
  const active = location.pathname.split('/').splice(-1)[0];

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} md={6}>
        <Box mb={{ base: 10 }}>
          <Menu
            mode="inline"
            defaultSelectedKeys="info"
            selectedKeys={[active]}
          >
            <Menu.Item key="info">
              <Link to={`${match.url}/info`}>基本資料</Link>
            </Menu.Item>
            <Menu.Item key="registration">
              <Link to={`${match.url}/registration`}>報名資料</Link>
            </Menu.Item>
            <Menu.Item key="learning">
              <Link to={`${match.url}/learning`}>學習資料</Link>
            </Menu.Item>
            <Menu.Item key="donation">
              <Link to={`${match.url}/donation`}>捐款資料</Link>
            </Menu.Item>
            <Menu.Item key="setting">
              <Link to={`${match.url}/setting`}>系統設定</Link>
            </Menu.Item>
          </Menu>
        </Box>
      </Col>
      <Col xs={24} md={18}>
        <Switch>
          <Route path={`${match.path}/:contentId`}>
            <ProfileContent user={user} />
          </Route>
          <Route path={match.path}>
            <UserInfo user={user} />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

export default ProfilePage;
