import { Divider, Table, Tag, Typography } from 'antd';
import { getSessionFilterOptions } from '../../utils';
import DownloadButton from './DownloadButton';

const { Title, Text } = Typography;

const UserLearningTable = ({ user }) => {
  const learnings = user['learnings'];

  const data = learnings.map((d, i) => {
    d.key = i;
    return d;
  });

  const columns = [
    { key: 'id', dataIndex: 'id', title: 'No' },
    {
      key: 'session',
      title: '課程場次',
      render: (text, record) => (
        <Text>
          {record.course_name}
          {record.session_name}
        </Text>
      ),
      filters: getSessionFilterOptions(data),
      onFilter: (value, record) =>
        record.course_name + record.session_name === value,
    },
    {
      key: 'course_start_date',
      title: '課程開始日期',
      dataIndex: 'course_start_date',
    },
    {
      key: 'course_end_date',
      title: '課程結束日期',
      dataIndex: 'course_end_date',
    },
    {
      key: 'finished',
      title: '狀態',
      render: (text, record) => (
        <Tag color={record.finished ? 'green' : 'blue'}>
          {record.finished ? '完成' : '還未完成'}
        </Tag>
      ),
    },
    {
      key: 'finished_date',
      title: '結業日期',
      dataIndex: 'finished_date',
      render: (text, record) =>
        record.finished ? <div>{record.finished_date}</div> : null,
    },
    {
      key: 'note',
      title: '備註',
      dataIndex: 'note',
    },
    {
      key: 'certificate',
      title: '結業證書',
      render: (text, record) =>
        record.finished ? <DownloadButton id={record.id} /> : null,
    },
  ];

  return (
    <>
      <Title level={4}>學習資料</Title>
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default UserLearningTable;
