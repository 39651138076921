import { Text } from '@chakra-ui/react';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

const ATMButton = ({ title, record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = e => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        ATM 繳款
      </Button>
      <Modal
        title={`${title}: ${record.id}`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            關閉
          </Button>,
        ]}
      >
        <Text fontSize={'lg'} mb={5}>
          銀行代碼 {record.ecpay.bank_code}
          <br></br>
          帳號 {record.ecpay.v_account}
        </Text>
        <Text fontSize={'md'}>請於 {record.ecpay.expire_date} 繳費</Text>
      </Modal>
    </>
  );
};

export default ATMButton;
