import { useQuery } from 'react-query';
import api from '../api';
import { isSessionForRegister } from '../utils';

const hasSession = course => {
  return course['sessions'].length > 0;
};

const removeNotOpenSession = course => {
  const sessions = course['sessions'].filter(session =>
    isSessionForRegister(session)
  );
  return { ...course, sessions };
};

export const useCourse = () => {
  const query = useQuery(['course'], () => api.Course.get());
  const courses = query.isSuccess ? query.data.data : [];
  const openCourses = query.isSuccess
    ? query.data.data.map(removeNotOpenSession).filter(hasSession)
    : [];

  return {
    courses: courses.sort((a, b) => a.id - b.id),
    openCourses: openCourses.sort((a, b) => a.id - b.id),
  };
};
