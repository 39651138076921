import axios from 'axios';

const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;
const ORGANIZATION_ID = process.env.REACT_APP_ORGANIZATION_ID;
const TOKEN_KEY = 'jwt';

const encode = encodeURIComponent;

function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

axios.interceptors.response.use(
  response => response,
  function (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 403) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

const requests = {
  del: (url, token) => axios.delete(`${API_ROOT}${url}`, authHeaders(token)),
  get: (url, token) => axios.get(`${API_ROOT}${url}`, authHeaders(token)),
  put: (url, body, token) =>
    axios.put(`${API_ROOT}${url}`, body, authHeaders(token)),
  post: (url, body, token) =>
    axios.post(`${API_ROOT}${url}`, body, authHeaders(token)),
};

const Auth = {
  login: (username, password) => {
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return axios.post(`${API_ROOT}/v1/login/access-token`, formData);
  },
  signup: (email, password) =>
    axios.post(`${API_ROOT}/v1/user/open/${ORGANIZATION_ID}`, {
      email: email,
      password: password,
    }),
  recoverPassword: email =>
    axios.post(
      `${API_ROOT}/v1/password-recovery/${ORGANIZATION_ID}/${encode(email)}`
    ),
  resetPassword: (token, newPassword) =>
    requests.post(
      '/v1/reset-password',
      { token: token, new_password: newPassword },
      token
    ),
};

const User = {
  me: token => requests.get('/v1/user/me', token),
  update: (data, token) => requests.put(`/v1/user/me`, data, token),
  createFreeRegistration: (data, token) =>
    requests.post(`/v1/user/registration/free/`, data, token),
  createOfflineRegistration: (data, priceId, token) =>
    requests.post(
      `/v1/user/registration/offline/?price_id=${priceId}`,
      data,
      token
    ),
  createEcpayRegistration: (data, type, priceId, token) =>
    requests.post(
      `/v1/user/registration/ecpay/?payment_type=${type}&price_id=${priceId}`,
      data,
      token
    ),
  updateRegistration: (id, data, token) =>
    requests.put(`/v1/user/registration/${id}`, data, token),
  createDonation: (data, type, token) =>
    requests.post(
      `/v1/user/donation/ecpay/${ORGANIZATION_ID}/?payment_type=${type}`,
      data,
      token
    ),
};

const Course = {
  get: () => requests.get(`/v1/course/public/${ORGANIZATION_ID}`),
};

const Campaign = {
  get: () => requests.get(`/v1/campaign/public/${ORGANIZATION_ID}`),
};

const Faq = {
  get: () => requests.get(`/v1/faq/public/${ORGANIZATION_ID}`),
};

const api = {
  Auth,
  User,
  Course,
  Campaign,
  Faq,
};

export default api;
