import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import { invoiceOptions } from '../../constants';

const { Option } = Select;

const InvoiceFormItem = ({ init }) => {
  const [invoice, setInvoice] = useState(init ? init : invoiceOptions[0]);

  const onSelectInvoice = value => {
    setInvoice(value);
  };

  return (
    <>
      <Form.Item
        label="發票處理方式"
        name="invoice"
        rules={[
          {
            required: true,
            message: '請選擇',
          },
        ]}
      >
        <Select onSelect={onSelectInvoice}>
          {invoiceOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {invoice === invoiceOptions[2] ? (
        <Form.Item
          label='發票統一編號'
          name="invoice_name"
        >
          <Input></Input>
        </Form.Item>
      ) : null}
    </>
  );
};

export default InvoiceFormItem;
