const siteOptions = {
  臺北市: [
    { district: '中正區', zip: '100' },
    { district: '大同區', zip: '103' },
    { district: '中山區', zip: '104' },
    { district: '松山區', zip: '105' },
    { district: '大安區', zip: '106' },
    { district: '萬華區', zip: '108' },
    { district: '信義區', zip: '110' },
    { district: '士林區', zip: '111' },
    { district: '北投區', zip: '112' },
    { district: '內湖區', zip: '114' },
    { district: '南港區', zip: '115' },
    { district: '文山區', zip: '116' },
  ],
  基隆市: [
    { district: '仁愛區', zip: '200' },
    { district: '信義區', zip: '201' },
    { district: '中正區', zip: '202' },
    { district: '中山區', zip: '203' },
    { district: '安樂區', zip: '204' },
    { district: '暖暖區', zip: '205' },
    { district: '七堵區', zip: '206' },
  ],
  新北市: [
    { district: '萬里區', zip: '207' },
    { district: '金山區', zip: '208' },
    { district: '板橋區', zip: '220' },
    { district: '汐止區', zip: '221' },
    { district: '深坑區', zip: '222' },
    { district: '石碇區', zip: '223' },
    { district: '瑞芳區', zip: '224' },
    { district: '平溪區', zip: '226' },
    { district: '雙溪區', zip: '227' },
    { district: '貢寮區', zip: '228' },
    { district: '新店區', zip: '231' },
    { district: '坪林區', zip: '232' },
    { district: '烏來區', zip: '233' },
    { district: '永和區', zip: '234' },
    { district: '中和區', zip: '235' },
    { district: '土城區', zip: '236' },
    { district: '三峽區', zip: '237' },
    { district: '樹林區', zip: '238' },
    { district: '鶯歌區', zip: '239' },
    { district: '三重區', zip: '241' },
    { district: '新莊區', zip: '242' },
    { district: '泰山區', zip: '243' },
    { district: '林口區', zip: '244' },
    { district: '蘆洲區', zip: '247' },
    { district: '五股區', zip: '248' },
    { district: '八里區', zip: '249' },
    { district: '淡水區', zip: '251' },
    { district: '三芝區', zip: '252' },
    { district: '石門區', zip: '253' },
  ],
  宜蘭縣: [
    { district: '宜蘭市', zip: '260' },
    { district: '頭城鎮', zip: '261' },
    { district: '礁溪鄉', zip: '262' },
    { district: '壯圍鄉', zip: '263' },
    { district: '員山鄉', zip: '264' },
    { district: '羅東鎮', zip: '265' },
    { district: '三星鄉', zip: '266' },
    { district: '大同鄉', zip: '267' },
    { district: '五結鄉', zip: '268' },
    { district: '冬山鄉', zip: '269' },
    { district: '蘇澳鎮', zip: '270' },
    { district: '南澳鄉', zip: '272' },
    { district: '釣魚台', zip: '290' },
  ],
  桃園市: [
    { district: '中壢區', zip: '320' },
    { district: '平鎮區', zip: '324' },
    { district: '龍潭區', zip: '325' },
    { district: '楊梅區', zip: '326' },
    { district: '新屋區', zip: '327' },
    { district: '觀音區', zip: '328' },
    { district: '桃園區', zip: '330' },
    { district: '龜山區', zip: '333' },
    { district: '八德區', zip: '334' },
    { district: '大溪區', zip: '335' },
    { district: '復興區', zip: '336' },
    { district: '大園區', zip: '337' },
    { district: '蘆竹區', zip: '338' },
  ],
  新竹市: [
    { district: '東區', zip: '300' },
    { district: '北區', zip: '300' },
    { district: '香山區', zip: '300' },
  ],
  新竹縣: [
    { district: '竹北市', zip: '302' },
    { district: '湖口鄉', zip: '303' },
    { district: '新豐鄉', zip: '304' },
    { district: '新埔鎮', zip: '305' },
    { district: '關西鎮', zip: '306' },
    { district: '芎林鄉', zip: '307' },
    { district: '寶山鄉', zip: '308' },
    { district: '竹東鎮', zip: '310' },
    { district: '五峰鄉', zip: '311' },
    { district: '橫山鄉', zip: '312' },
    { district: '尖石鄉', zip: '313' },
    { district: '北埔鄉', zip: '314' },
    { district: '峨眉鄉', zip: '315' },
  ],
  苗栗縣: [
    { district: '竹南鎮', zip: '350' },
    { district: '頭份市', zip: '351' },
    { district: '三灣鄉', zip: '352' },
    { district: '南庄鄉', zip: '353' },
    { district: '獅潭鄉', zip: '354' },
    { district: '後龍鎮', zip: '356' },
    { district: '通霄鎮', zip: '357' },
    { district: '苑裡鎮', zip: '358' },
    { district: '苗栗市', zip: '360' },
    { district: '造橋鄉', zip: '361' },
    { district: '頭屋鄉', zip: '362' },
    { district: '公館鄉', zip: '363' },
    { district: '大湖鄉', zip: '364' },
    { district: '泰安鄉', zip: '365' },
    { district: '銅鑼鄉', zip: '366' },
    { district: '三義鄉', zip: '367' },
    { district: '西湖鄉', zip: '368' },
    { district: '卓蘭鎮', zip: '369' },
  ],
  臺中市: [
    { district: '中區', zip: '400' },
    { district: '東區', zip: '401' },
    { district: '南區', zip: '402' },
    { district: '西區', zip: '403' },
    { district: '北區', zip: '404' },
    { district: '北屯區', zip: '406' },
    { district: '西屯區', zip: '407' },
    { district: '南屯區', zip: '408' },
    { district: '太平區', zip: '411' },
    { district: '大里區', zip: '412' },
    { district: '霧峰區', zip: '413' },
    { district: '烏日區', zip: '414' },
    { district: '豐原區', zip: '420' },
    { district: '后里區', zip: '421' },
    { district: '石岡區', zip: '422' },
    { district: '東勢區', zip: '423' },
    { district: '和平區', zip: '424' },
    { district: '新社區', zip: '426' },
    { district: '潭子區', zip: '427' },
    { district: '大雅區', zip: '428' },
    { district: '神岡區', zip: '429' },
    { district: '大肚區', zip: '432' },
    { district: '沙鹿區', zip: '433' },
    { district: '龍井區', zip: '434' },
    { district: '梧棲區', zip: '435' },
    { district: '清水區', zip: '436' },
    { district: '大甲區', zip: '437' },
    { district: '外埔區', zip: '438' },
    { district: '大安區', zip: '439' },
  ],
  彰化縣: [
    { district: '彰化市', zip: '500' },
    { district: '芬園鄉', zip: '502' },
    { district: '花壇鄉', zip: '503' },
    { district: '秀水鄉', zip: '504' },
    { district: '鹿港鎮', zip: '505' },
    { district: '福興鄉', zip: '506' },
    { district: '線西鄉', zip: '507' },
    { district: '和美鎮', zip: '508' },
    { district: '伸港鄉', zip: '509' },
    { district: '員林市', zip: '510' },
    { district: '社頭鄉', zip: '511' },
    { district: '永靖鄉', zip: '512' },
    { district: '埔心鄉', zip: '513' },
    { district: '溪湖鎮', zip: '514' },
    { district: '大村鄉', zip: '515' },
    { district: '埔鹽鄉', zip: '516' },
    { district: '田中鎮', zip: '520' },
    { district: '北斗鎮', zip: '521' },
    { district: '田尾鄉', zip: '522' },
    { district: '埤頭鄉', zip: '523' },
    { district: '溪州鄉', zip: '524' },
    { district: '竹塘鄉', zip: '525' },
    { district: '二林鎮', zip: '526' },
    { district: '大城鄉', zip: '527' },
    { district: '芳苑鄉', zip: '528' },
    { district: '二水鄉', zip: '530' },
  ],
  南投縣: [
    { district: '南投市', zip: '540' },
    { district: '中寮鄉', zip: '541' },
    { district: '草屯鎮', zip: '542' },
    { district: '國姓鄉', zip: '544' },
    { district: '埔里鎮', zip: '545' },
    { district: '仁愛鄉', zip: '546' },
    { district: '名間鄉', zip: '551' },
    { district: '集集鎮', zip: '552' },
    { district: '水里鄉', zip: '553' },
    { district: '魚池鄉', zip: '555' },
    { district: '信義鄉', zip: '556' },
    { district: '竹山鎮', zip: '557' },
    { district: '鹿谷鄉', zip: '558' },
  ],
  嘉義市: [
    { district: '東區', zip: '600' },
    { district: '西區', zip: '600' },
  ],
  嘉義縣: [
    { district: '番路鄉', zip: '602' },
    { district: '梅山鄉', zip: '603' },
    { district: '竹崎鄉', zip: '604' },
    { district: '阿里山', zip: '605' },
    { district: '中埔鄉', zip: '606' },
    { district: '大埔鄉', zip: '607' },
    { district: '水上鄉', zip: '608' },
    { district: '鹿草鄉', zip: '611' },
    { district: '太保市', zip: '612' },
    { district: '朴子市', zip: '613' },
    { district: '東石鄉', zip: '614' },
    { district: '六腳鄉', zip: '615' },
    { district: '新港鄉', zip: '616' },
    { district: '民雄鄉', zip: '621' },
    { district: '大林鎮', zip: '622' },
    { district: '溪口鄉', zip: '623' },
    { district: '義竹鄉', zip: '624' },
    { district: '布袋鎮', zip: '625' },
  ],
  雲林縣: [
    { district: '斗南鎮', zip: '630' },
    { district: '大埤鄉', zip: '631' },
    { district: '虎尾鎮', zip: '632' },
    { district: '土庫鎮', zip: '633' },
    { district: '褒忠鄉', zip: '634' },
    { district: '東勢鄉', zip: '635' },
    { district: '臺西鄉', zip: '636' },
    { district: '崙背鄉', zip: '637' },
    { district: '麥寮鄉', zip: '638' },
    { district: '斗六市', zip: '640' },
    { district: '林內鄉', zip: '643' },
    { district: '古坑鄉', zip: '646' },
    { district: '莿桐鄉', zip: '647' },
    { district: '西螺鎮', zip: '648' },
    { district: '二崙鄉', zip: '649' },
    { district: '北港鎮', zip: '651' },
    { district: '水林鄉', zip: '652' },
    { district: '口湖鄉', zip: '653' },
    { district: '四湖鄉', zip: '654' },
    { district: '元長鄉', zip: '655' },
  ],
  臺南市: [
    { district: '中西區', zip: '700' },
    { district: '東區', zip: '701' },
    { district: '南區', zip: '702' },
    { district: '北區', zip: '704' },
    { district: '安平區', zip: '708' },
    { district: '安南區', zip: '709' },
    { district: '永康區', zip: '710' },
    { district: '歸仁區', zip: '711' },
    { district: '新化區', zip: '712' },
    { district: '左鎮區', zip: '713' },
    { district: '玉井區', zip: '714' },
    { district: '楠西區', zip: '715' },
    { district: '南化區', zip: '716' },
    { district: '仁德區', zip: '717' },
    { district: '關廟區', zip: '718' },
    { district: '龍崎區', zip: '719' },
    { district: '官田區', zip: '720' },
    { district: '麻豆區', zip: '721' },
    { district: '佳里區', zip: '722' },
    { district: '西港區', zip: '723' },
    { district: '七股區', zip: '724' },
    { district: '將軍區', zip: '725' },
    { district: '學甲區', zip: '726' },
    { district: '北門區', zip: '727' },
    { district: '新營區', zip: '730' },
    { district: '後壁區', zip: '731' },
    { district: '白河區', zip: '732' },
    { district: '東山區', zip: '733' },
    { district: '六甲區', zip: '734' },
    { district: '下營區', zip: '735' },
    { district: '柳營區', zip: '736' },
    { district: '鹽水區', zip: '737' },
    { district: '善化區', zip: '741' },
    { district: '大內區', zip: '742' },
    { district: '山上區', zip: '743' },
    { district: '新市區', zip: '744' },
    { district: '安定區', zip: '745' },
  ],
  高雄市: [
    { district: '新興區', zip: '800' },
    { district: '前金區', zip: '801' },
    { district: '苓雅區', zip: '802' },
    { district: '鹽埕區', zip: '803' },
    { district: '鼓山區', zip: '804' },
    { district: '旗津區', zip: '805' },
    { district: '前鎮區', zip: '806' },
    { district: '三民區', zip: '807' },
    { district: '楠梓區', zip: '811' },
    { district: '小港區', zip: '812' },
    { district: '左營區', zip: '813' },
    { district: '仁武區', zip: '814' },
    { district: '大社區', zip: '815' },
    { district: '東沙群島', zip: '817' },
    { district: '南沙群島', zip: '819' },
    { district: '岡山區', zip: '820' },
    { district: '路竹區', zip: '821' },
    { district: '阿蓮區', zip: '822' },
    { district: '田寮區', zip: '823' },
    { district: '燕巢區', zip: '824' },
    { district: '橋頭區', zip: '825' },
    { district: '梓官區', zip: '826' },
    { district: '彌陀區', zip: '827' },
    { district: '永安區', zip: '828' },
    { district: '湖內區', zip: '829' },
    { district: '鳳山區', zip: '830' },
    { district: '大寮區', zip: '831' },
    { district: '林園區', zip: '832' },
    { district: '鳥松區', zip: '833' },
    { district: '大樹區', zip: '840' },
    { district: '旗山區', zip: '842' },
    { district: '美濃區', zip: '843' },
    { district: '六龜區', zip: '844' },
    { district: '內門區', zip: '845' },
    { district: '杉林區', zip: '846' },
    { district: '甲仙區', zip: '847' },
    { district: '桃源區', zip: '848' },
    { district: '那瑪夏區', zip: '849' },
    { district: '茂林區', zip: '851' },
    { district: '茄萣區', zip: '852' },
  ],
  澎湖縣: [
    { district: '馬公市', zip: '880' },
    { district: '西嶼鄉', zip: '881' },
    { district: '望安鄉', zip: '882' },
    { district: '七美鄉', zip: '883' },
    { district: '白沙鄉', zip: '884' },
    { district: '湖西鄉', zip: '885' },
  ],
  金門縣: [
    { district: '金沙鎮', zip: '890' },
    { district: '金湖鎮', zip: '891' },
    { district: '金寧鄉', zip: '892' },
    { district: '金城鎮', zip: '893' },
    { district: '烈嶼鄉', zip: '894' },
    { district: '烏坵鄉', zip: '896' },
  ],
  屏東縣: [
    { district: '屏東市', zip: '900' },
    { district: '三地門鄉', zip: '901' },
    { district: '霧台鄉', zip: '902' },
    { district: '瑪家鄉', zip: '903' },
    { district: '九如鄉', zip: '904' },
    { district: '里港鄉', zip: '905' },
    { district: '高樹鄉', zip: '906' },
    { district: '鹽埔鄉', zip: '907' },
    { district: '長治鄉', zip: '908' },
    { district: '麟洛鄉', zip: '909' },
    { district: '竹田鄉', zip: '911' },
    { district: '內埔鄉', zip: '912' },
    { district: '萬丹鄉', zip: '913' },
    { district: '潮州鎮', zip: '920' },
    { district: '泰武鄉', zip: '921' },
    { district: '來義鄉', zip: '922' },
    { district: '萬巒鄉', zip: '923' },
    { district: '崁頂鄉', zip: '924' },
    { district: '新埤鄉', zip: '925' },
    { district: '南州鄉', zip: '926' },
    { district: '林邊鄉', zip: '927' },
    { district: '東港鎮', zip: '928' },
    { district: '琉球鄉', zip: '929' },
    { district: '佳冬鄉', zip: '931' },
    { district: '新園鄉', zip: '932' },
    { district: '枋寮鄉', zip: '940' },
    { district: '枋山鄉', zip: '941' },
    { district: '春日鄉', zip: '942' },
    { district: '獅子鄉', zip: '943' },
    { district: '車城鄉', zip: '944' },
    { district: '牡丹鄉', zip: '945' },
    { district: '恆春鎮', zip: '946' },
    { district: '滿州鄉', zip: '947' },
  ],
  臺東縣: [
    { district: '臺東市', zip: '950' },
    { district: '綠島鄉', zip: '951' },
    { district: '蘭嶼鄉', zip: '952' },
    { district: '延平鄉', zip: '953' },
    { district: '卑南鄉', zip: '954' },
    { district: '鹿野鄉', zip: '955' },
    { district: '關山鎮', zip: '956' },
    { district: '海端鄉', zip: '957' },
    { district: '池上鄉', zip: '958' },
    { district: '東河鄉', zip: '959' },
    { district: '成功鎮', zip: '961' },
    { district: '長濱鄉', zip: '962' },
    { district: '太麻里', zip: '963' },
    { district: '金峰鄉', zip: '964' },
    { district: '大武鄉', zip: '965' },
    { district: '達仁鄉', zip: '966' },
  ],
  花蓮縣: [
    { district: '花蓮市', zip: '970' },
    { district: '新城鄉', zip: '971' },
    { district: '秀林鄉', zip: '972' },
    { district: '吉安鄉', zip: '973' },
    { district: '壽豐鄉', zip: '974' },
    { district: '鳳林鎮', zip: '975' },
    { district: '光復鄉', zip: '976' },
    { district: '豐濱鄉', zip: '977' },
    { district: '瑞穗鄉', zip: '978' },
    { district: '萬榮鄉', zip: '979' },
    { district: '玉里鎮', zip: '981' },
    { district: '卓溪鄉', zip: '982' },
    { district: '富里鄉', zip: '983' },
  ],
  連江縣: [
    { district: '南竿鄉', zip: '209' },
    { district: '北竿鄉', zip: '210' },
    { district: '莒光鄉', zip: '211' },
    { district: '東引鄉', zip: '212' },
  ],
  海外: [{ district: '海外', zip: '000' }],
};

const cityOptions = Object.keys(siteOptions);

export { siteOptions, cityOptions };
