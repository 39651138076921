import { useQuery } from 'react-query';
import api from '../api';

export const useCampaign = () => {
  const campaigns = useQuery(['campaign'], () => api.Campaign.get());

  return {
    campaigns: campaigns.isSuccess
      ? campaigns.data.data.sort((a, b) => a.id - b.id)
      : [],
  };
};
