import { Box, Container } from '@chakra-ui/layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from './components/NavBar';
import ProtectedRoute from './components/ProtectedRoute';
import CampaignPage from './pages/CampaignPage';
import CoursePage from './pages/CoursePage';
import FaqPage from './pages/FaqPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';

function DefaultLayout() {
  return (
    <>
      <header>
        <NavBar />
      </header>
      <main>
        <Box px={{ base: 10 }} py={{ base: 10 }}>
          <Container maxW="container.xl">
            <Switch>
              <Route exact key="home" path="/">
                <HomePage />
              </Route>
              <Route exact key="faq" path="/faq">
                <FaqPage />
              </Route>
              <Route key="course/:courseId" path="/course/:courseId">
                <CoursePage />
              </Route>
              <Route key="campaign/:campaignId" path="/campaign/:campaignId">
                <CampaignPage />
              </Route>
              <ProtectedRoute key="profile" path="/profile">
                <ProfilePage />
              </ProtectedRoute>
            </Switch>
          </Container>
        </Box>
      </main>
      <footer></footer>
    </>
  );
}

export default DefaultLayout;
