import { useContext } from 'react';
import { useQuery } from 'react-query';
import api from '../api';
import { AuthContext } from '../components/AuthContext';

export const useMe = () => {
  const { token } = useContext(AuthContext);

  const me = useQuery(['me', { token }], () => api.User.me(token));

  return {
    user: me.isSuccess ? me.data.data : undefined,
    loading: me.isLoading,
  };
};
