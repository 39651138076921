import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import api from '../api';
import { AuthContext } from '../components/AuthContext';

export const useUser = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const update = useMutation(({ data }) => api.User.update(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('me');
    },
  });

  const createFreeRegistration = useMutation(
    ({ data }) => api.User.createFreeRegistration(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
      },
    }
  );

  const createOfflineRegistration = useMutation(
    ({ data, priceId }) =>
      api.User.createOfflineRegistration(data, priceId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
      },
    }
  );
  const createEcpayRegistration = useMutation(
    ({ data, type, priceId }) =>
      api.User.createEcpayRegistration(data, type, priceId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
      },
    }
  );

  const updateRegistration = useMutation(
    ({ id, data }) => api.User.updateRegistration(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
      },
    }
  );

  const createDonation = useMutation(
    ({ data, type }) => api.User.createDonation(data, type, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
      },
    }
  );

  return {
    updateUser: update,
    createFreeRegistration,
    createOfflineRegistration,
    createEcpayRegistration,
    updateRegistration,
    createDonation,
  };
};
