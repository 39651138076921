import { Form, Input } from 'antd';
import React from 'react';

const MobileFormItem = ({ required = false }) => {
  return (
    <Form.Item
      label="手機"
      name="mobile"
      rules={[
        {
          required: required,
          pattern: /09[0-9]{8}$/,
          message: '請填寫正確格式',
        },
      ]}
    >
      <Input placeholder="0900000000"></Input>
    </Form.Item>
  );
};

export default MobileFormItem;
