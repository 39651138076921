import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
} from '@chakra-ui/react';
import { message } from 'antd';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReachLink, useLocation } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { isNumberRegx, specialCharacterRegx } from '../utils';

function ResetPassword() {
  const [error, setError] = useState(null);
  const { resetPassword, loading } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
  } = useForm();

  const handleClick = () => setShow(!show);
  function onSubmit(values) {
    const token = query.get('token');
    resetPassword(token, values.password)
      .then(({ data }) => {
        message.success(data.msg);
      })
      .catch(err => {
        setError(err);
      });
  }

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} minW={'400px'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>更改密碼</Heading>
        </Stack>
        <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="password" isInvalid={errors.password}>
                <FormLabel htmlFor="password">新密碼</FormLabel>
                <InputGroup>
                  <Input
                    name="password"
                    type={show ? 'text' : 'password'}
                    placeholder="password"
                    {...register('password', {
                      required: '請填寫密碼',
                      minLength: {
                        value: 8,
                        message: '至少要有八個字元',
                      },
                      validate: {
                        number: v => isNumberRegx.test(v) || '至少要有一個數字',
                        specialChar: v =>
                          specialCharacterRegx.test(v) ||
                          '至少要有一個特殊字元，例如!@#$%^&*()_+-=[]{};:|,.<>/?',
                      },
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText>
                  至少要有8個字元（包含1個特殊字元與1個數字）
                </FormHelperText>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                color="white"
                bg="brand.600"
                isLoading={formState.isSubmitting || loading}
                type="submit"
                _hover={{
                  bg: 'brand.500',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                更改
              </Button>
              {error ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {error.response && error.response.data['detail']}
                  </AlertDescription>
                </Alert>
              ) : null}
            </Stack>
          </form>
        </Box>
        <Box textAlign="center">
          <Link as={ReachLink} to="/">
            回首頁
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}

export default ResetPassword;
