import { VisuallyHidden } from '@chakra-ui/react';
import { Button } from 'antd';
import React, { useEffect } from 'react';

const EcpayButton = ({
  record,
  createRecord,
  paymentType,
  paymentTypeName,
  priceId,
}) => {
  const onClickPayOnline = () => {
    createRecord.mutateAsync({
      data: record,
      priceId: priceId,
      type: paymentType,
    });
  };

  useEffect(() => {
    if (createRecord.isSuccess && document.querySelector('#data_set')) {
      document.querySelector('#data_set').submit();
    }
  }, [createRecord.isSuccess]);

  return (
    <>
      <Button onClick={onClickPayOnline} loading={createRecord.isLoading}>
        {paymentTypeName}
      </Button>
      <VisuallyHidden>
        {createRecord.isSuccess ? (
          <div
            dangerouslySetInnerHTML={{
              __html: createRecord.data.data,
            }}
          ></div>
        ) : null}
      </VisuallyHidden>
    </>
  );
};

export default EcpayButton;
