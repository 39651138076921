import { Button, Card, Divider, Form, Input, message, Typography } from 'antd';
import { useUser } from '../../hooks/user';
import { isNumberRegx, specialCharacterRegx } from '../../utils';

const { Title } = Typography;

const UserSettingForm = () => {
  const { updateUser } = useUser();
  const [form] = Form.useForm();

  const onFinish = values => {
    const data = { password: values.password };
    updateUser
      .mutateAsync({ data: data })
      .then(() => {
        message.success('更改成功');
        form.resetFields();
      })
      .catch(err => {
        message.error(err.response.data['detail']);
      });
  };

  return (
    <Card>
      <Title level={4}>密碼設定</Title>
      <Divider></Divider>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          label="新密碼"
          name="password"
          help="至少要有8個字元（包含1個特殊字元與1個數字）"
          rules={[
            {
              required: true,
              message: '請輸入新密碼',
            },
            () => ({
              validator(_, value) {
                if (
                  isNumberRegx.test(value) &&
                  specialCharacterRegx.test(value) &&
                  value.length >= 8
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('至少要有8個字元（包含1個特殊字元與1個數字）')
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="確認新密碼"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '請確認新密碼',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('和新密碼不一樣'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          style={{ marginTop: 6 }}
        >
          更改
        </Button>
      </Form>
    </Card>
  );
};

export default UserSettingForm;
