import { Form, InputNumber, Radio, Space } from 'antd';

const AmountFormItem = ({ label, name, required = false, options = [] }) => {
  return (
    <Form.Item label={label} name={[name, 'default']} required={required}>
      <Radio.Group>
        <Space direction="vertical">
          {options.map(i => (
            <Radio value={i['amount']} key={i['amount']}>
              {i['amount']} {i['name'] ? ' - ' : null} {i['name']}
            </Radio>
          ))}
          <Radio value={'自訂'}>
            自訂
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[name].default !== currentValues[name].default
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(name).default === '自訂' ? (
                  <Form.Item
                    name={[name, 'custom']}
                    noStyle
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={10000000000}
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export default AmountFormItem;
