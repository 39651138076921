import { Stack } from '@chakra-ui/react';
import { Skeleton, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import RegistrationForm from './RegistrationForm';

const { Text, Title } = Typography;

const RegistrationCheckForm = ({
  user,
  session,
  openCourses,
  createRegistration,
  setRegistration,
  nextStep,
}) => {
  const [allow, setAllow] = useState(true);

  const passIdentityCondition = (price, identities) => {
    return price.identity === '無' || identities.includes(price.identity);
  };

  const passDeadlineCondition = price => {
    return (
      (price.deadline &&
        moment(price.deadline).local().add(1, 'days') >= moment()) ||
      !price.deadline
    );
  };

  useEffect(() => {
    const hasPriceToSelect = (prices, identities) => {
      const free = prices.length === 0;
      const hasPrice =
        prices.filter(
          i => passDeadlineCondition(i) && passIdentityCondition(i, identities)
        ).length > 0;
      return free || hasPrice;
    };

    const course = openCourses.find(i => i.name === session.course_name);
    const prerequisite =
      course.prerequisite && course.prerequisite !== ''
        ? course.prerequisite.split('; ')
        : [];
    const learn = user ? user['learnings'].map(j => j.course_name) : [];
    const identities =
      user && user.identities ? user.identities.split('; ') : [];
    const hasPrice = hasPriceToSelect(session.prices, identities);
    const completed =
      prerequisite.filter(k => learn.includes(k)).length ===
      prerequisite.length;
    setAllow(completed && hasPrice);
  }, [user, session, openCourses]);

  return (
    <Stack align={'center'}>
      {allow ? (
        <RegistrationForm
          session={session}
          user={user}
          createRegistration={createRegistration}
          setRegistration={setRegistration}
          nextStep={nextStep}
        />
      ) : session ? (
        <>
          <Title level={4}>
            你目前不能選擇{session.course_name}
            {session.name}
          </Title>
          <Text>可能是沒有滿足先修課程或是身份不符合</Text>
        </>
      ) : (
        <Skeleton />
      )}
    </Stack>
  );
};

export default RegistrationCheckForm;
