import moment from 'moment';
import React, { useState } from 'react';
import { officialType } from '../../constants';
import { dateFormat } from '../../utils';
import EditButton from '../EditButton';
import UserForm from './UserForm';

const EditUserButton = ({ user, onUpdate }) => {
  const [visible, setVisible] = useState(false);
  const formId = 'edit_user';
  const initialValues = {
    ...user,
    official_type: user.official_type ? user.official_type : officialType[0],
    birthdate: user.birthdate ? moment(user.birthdate) : undefined,
    address: {
      city: [user.address_city, user.address_site],
      street: user.address_street,
    },
  };

  const onFinish = values => {
    const data = {
      ...values,
      birthdate: values.birthdate.format(dateFormat),
      address_city: values.address.city[0],
      address_site: values.address.city[1],
      address_street: values.address.street,
    };
    onUpdate.mutateAsync({ id: user.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={'會員'}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={onUpdate.isLoading}
      children={
        <UserForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditUserButton;
