import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import UserDonationTable from './UserDonationTable';
import UserInfo from './UserInfo';
import UserLearningTable from './UserLearningTable';
import UserRegistrationTable from './UserRegistrationTable';
import UserSettingForm from './UserSettingForm';

const ProfileContent = ({ user }) => {
  const { contentId } = useParams();

  return (
    <>
      {user ? (
        <>
          {contentId === 'info' ? <UserInfo user={user} /> : null}
          {contentId === 'registration' ? (
            <UserRegistrationTable user={user} />
          ) : null}
          {contentId === 'learning' ? <UserLearningTable user={user} /> : null}
          {contentId === 'donation' ? <UserDonationTable user={user} /> : null}
          {contentId === 'setting' ? <UserSettingForm /> : null}
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default ProfileContent;
