import { Button, message } from 'antd';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import { useContext, useState } from 'react';
import { AuthContext } from '../../components/AuthContext';
import { getErrorMessage } from '../../utils';

const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;

const DonwloadButton = ({ id }) => {
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onDownload = id => {
    setLoading(true);
    axios({
      url: `${API_ROOT}/v1/user/learning/${id}/certificate`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        const data = new Blob([response.data], { type: 'image/jpeg' });
        FileSaver.saveAs(data);
      })
      .catch(err => {
        message.error(getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      onClick={() => onDownload(id)}
      type="text"
      size="small"
      loading={loading}
    >
      下載
    </Button>
  );
};

export default DonwloadButton;
