import { DatePicker, Form, Input, Select, Switch } from 'antd';
import {
  educationOptions,
  maritalStatusOptions,
  professionOptions,
  religionOptions,
} from '../../constants';
import AddressFormItem from '../AddressFormItem';
import MobileFormItem from '../MobileFormItem';
import OfficialIdFormItem from '../OfficialIdFormItem';

const { Option } = Select;

const UserForm = ({ initialValues = {}, formId, onFinish }) => {
  return (
    <Form
      id={formId}
      layout={'vertical'}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="姓名"
        name="name"
        rules={[
          {
            required: true,
            message: '請填寫',
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <OfficialIdFormItem required={false} />
      <AddressFormItem label={'通訊地址'} name={'address'} required={true} />
      <Form.Item
        label="生日"
        name="birthdate"
        rules={[
          {
            required: true,
            message: '請選擇',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item label="性別" name="gender">
        <Select>
          <Option value="男" key="男">
            男生
          </Option>
          <Option value="女" key="女">
            女生
          </Option>
        </Select>
      </Form.Item>
      <MobileFormItem required={true} />
      <Form.Item label="市話" name="home_phone">
        <Input placeholder="02-00000000"></Input>
      </Form.Item>
      <Form.Item label="公司/學校（年級）" name="company">
        <Input></Input>
      </Form.Item>
      <Form.Item label="婚姻狀態" name="marital_status">
        <Select>
          {maritalStatusOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="職業" name="profession">
        <Select>
          {professionOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="信仰" name="religion">
        <Select>
          {religionOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="教育程度" name="education">
        <Select>
          {educationOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="團隊" name="team">
        <Input></Input>
      </Form.Item>
      <Form.Item label="所屬區辦" name="office">
        <Input></Input>
      </Form.Item>
      <Form.Item label="學習護照號碼" name="student_id">
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="是否訂閱月刊"
        name="order_newsletter"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default UserForm;
