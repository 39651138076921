import { Button, DatePicker, Divider, Form, Input, Select, Switch } from 'antd';
import moment from 'moment';
import { religionOptions } from '../../constants';
import { dateFormat } from '../../utils';
import AddressFormItem from '../AddressFormItem';
import MobileFormItem from '../MobileFormItem';
import ExtraFormItem from './ExtraFormItem';

const { TextArea } = Input;
const { Option } = Select;

const RegistrationInfoConfirmForm = ({
  nextStep,
  user,
  session,
  onUpdate,
  registration,
  setRegistration,
}) => {
  const [form] = Form.useForm();

  const onFinish = values => {
    const data = {
      name: values.name,
      mobile: values.mobile,
      religion: values.religion,
      order_newsletter: values.order_newsletter,
      birthdate: values.birthdate.format(dateFormat),
      address_city: values.address.city[0],
      address_site: values.address.city[1],
      address_street: values.address.street,
    };
    onUpdate
      .mutateAsync({ data: data })
      .then(() => {
        setRegistration({
          ...registration,
          note: values.note,
          extra: values.extra ? JSON.stringify(values.extra) : '[]',
        });
      })
      .then(() => {
        nextStep();
      });
  };

  return (
    <Form
      layout={'vertical'}
      form={form}
      initialValues={{
        ...user,
        birthdate: user?.birthdate ? moment(user.birthdate) : undefined,
        address: {
          city: [user?.address_city, user?.address_site],
          street: user?.address_street,
        },
        note: '',
        extra: JSON.parse(session.extra),
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label="姓名"
        name="name"
        help={'此欄將列於結業證書，請同身分證件姓名；請勿代替他人報名課程'}
      >
        <Input></Input>
      </Form.Item>
      <AddressFormItem
        label={'居住地址'}
        name={'address'}
        required={true}
        help={'報名線上課程者，請務必確認可收掛號信，以利收到紙本教材/發票'}
      />
      <Form.Item
        label="生日"
        name="birthdate"
        rules={[
          {
            required: true,
            message: '請選擇',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <MobileFormItem required={true} />
      <Form.Item label="信仰" name="religion">
        <Select style={{ width: '30%' }}>
          {religionOptions.map(i => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="是否訂閱月刊"
        name="order_newsletter"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Divider />
      <ExtraFormItem extra={JSON.parse(session.extra)} />
      <Form.Item label="備註（如果有特殊需求請填寫）" name="note">
        <TextArea rows={3} />
      </Form.Item>
      <Button
        key="submit"
        htmlType="submit"
        type="primary"
        block
        loading={onUpdate.isLoading}
      >
        確認且存檔
      </Button>
    </Form>
  );
};

export default RegistrationInfoConfirmForm;
