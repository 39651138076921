import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { Button } from 'antd';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RegistrationCheckForm from '../components/registration/RegistrationCheckForm';
import RegistrationInfoConfirmForm from '../components/registration/RegistrationInfoConfirmForm';
import RegistrationPayForm from '../components/registration/RegistrationPayForm';
import { useCourse } from '../hooks/course';
import { useMe } from '../hooks/me';
import { useUser } from '../hooks/user';

const RegisterPage = () => {
  const history = useHistory();

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const { selectedSessionId } = useParams();
  const [session, setSession] = useState(undefined);
  const [registration, setRegistration] = useState(undefined);
  const { openCourses } = useCourse();
  const {
    updateUser,
    createFreeRegistration,
    createOfflineRegistration,
    createEcpayRegistration,
  } = useUser();
  const { user } = useMe();

  const steps = [
    {
      label: '確認場次',
      content: (
        <RegistrationCheckForm
          user={user}
          session={session}
          openCourses={openCourses}
          setRegistration={setRegistration}
          nextStep={nextStep}
        />
      ),
    },
    {
      label: '報名資料',
      content: (
        <RegistrationInfoConfirmForm
          user={user}
          session={session}
          registration={registration}
          setRegistration={setRegistration}
          onUpdate={updateUser}
          nextStep={nextStep}
        />
      ),
    },
    {
      label: '報名',
      content: (
        <RegistrationPayForm
          session={session}
          registration={registration}
          createFreeRegistration={createFreeRegistration}
          createOfflineRegistration={createOfflineRegistration}
          createEcpayRegistration={createEcpayRegistration}
        />
      ),
    },
  ];

  useEffect(() => {
    for (const course of openCourses) {
      const session = course['sessions'].find(
        j => j.id === parseInt(selectedSessionId)
      );
      if (session) {
        setSession(session);
      }
    }
  }, [selectedSessionId, openCourses]);

  return (
    <>
      {session ? (
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
          <Stack
            spacing={8}
            mx={'auto'}
            maxW={'xl'}
            minW={'400px'}
            py={12}
            px={6}
          >
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>報名課程</Heading>
            </Stack>
            <Stack mt={5}>
              <Steps activeStep={activeStep} colorScheme="brand">
                {steps.map(({ label, content }) => (
                  <Step label={label} key={label}>
                    <Stack spacing={10}>
                      <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
                        {content}
                      </Box>
                      <Box textAlign="center">
                        {activeStep === 0 ? (
                          <Button type="link" onClick={() => history.goBack()}>
                            上一頁
                          </Button>
                        ) : (
                          <Button type="link" onClick={prevStep}>
                            回上一步
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Step>
                ))}
              </Steps>
            </Stack>
          </Stack>
        </Flex>
      ) : null}
    </>
  );
};

export default RegisterPage;
