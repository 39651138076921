import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.less';
import AuthProvider from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import DefaultLayout from './DefaultLayout';
import DonatePage from './pages/DonatePage';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';
import RegisterPage from './pages/RegisterPage';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import TermPage from './pages/TermPage';

const queryClient = new QueryClient();

const theme = extendTheme({
  colors: {
    brand: {
      100: '#e6f7ff',
      200: '#bae7ff',
      300: '#91d5ff',
      400: '#69c0ff',
      500: '#40a9ff',
      600: '#1890ff',
      700: '#096dd9',
      800: '#0050b3',
      900: '#003a8c',
    },
  },
  components: {
    Steps,
  },
});

function App() {
  return (
    <ConfigProvider locale={zhTW}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={props => <Login {...props} />}
                />
                <Route
                  exact
                  path="/signup"
                  render={props => <Signup {...props} />}
                />
                <Route
                  exact
                  path="/reset_password"
                  render={props => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/recover_password"
                  render={props => <RecoverPassword {...props} />}
                />
                <Route exact path="/term" render={() => <TermPage />} />
                <Route path="/donation/create/:selectedCampaignId">
                  <DonatePage />
                </Route>
                <ProtectedRoute path="/registration/create/:selectedSessionId">
                  <RegisterPage />
                </ProtectedRoute>
                <Route
                  path="/"
                  render={props => <DefaultLayout {...props} />}
                />
              </Switch>
              <ReactQueryDevtools initialIsOpen={false} />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ChakraProvider>
    </ConfigProvider>
  );
}

export default App;
