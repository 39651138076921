import { Flex, Spacer } from '@chakra-ui/react';
import { Descriptions, Divider, Space, Typography } from 'antd';
import { useUser } from '../../hooks/user';
import { getDisplayAddress } from '../../utils';
import EditUserButton from './EditUserButton';

const { Title } = Typography;

const UserInfo = ({ user }) => {
  const { updateUser } = useUser();

  return (
    <>
      {user ? (
        <>
          <Flex>
            <Title level={4}>基本資料</Title>
            <Spacer />
            <Space>
              <EditUserButton user={user} onUpdate={updateUser} />
            </Space>
          </Flex>
          <Divider></Divider>
          <Descriptions column={{ xs: 1, sm: 1, md: 2, xl: 3 }}>
            <Descriptions.Item label="姓名">{user.name}</Descriptions.Item>
            <Descriptions.Item label="會員編號">{user.uuid}</Descriptions.Item>
            <Descriptions.Item label="帳號">{user.email}</Descriptions.Item>
            <Descriptions.Item
              label={user.official_type ? user.official_type : '身份證字號'}
            >
              {user.official_id}
            </Descriptions.Item>
            <Descriptions.Item label="通訊地址">
              {getDisplayAddress(
                user.address_city,
                user.address_site,
                user.address_street
              )}
            </Descriptions.Item>
            <Descriptions.Item label="生日">{user.birthdate}</Descriptions.Item>
            <Descriptions.Item label="性別">{user.gender}</Descriptions.Item>
            <Descriptions.Item label="手機">{user.mobile}</Descriptions.Item>
            <Descriptions.Item label="市話">
              {user.home_phone}
            </Descriptions.Item>
            <Descriptions.Item label="公司/學校（年級）">
              {user.company}
            </Descriptions.Item>
            <Descriptions.Item label="婚姻狀態">
              {user.marital_status}
            </Descriptions.Item>
            <Descriptions.Item label="職業">
              {user.profession}
            </Descriptions.Item>
            <Descriptions.Item label="教育程度">
              {user.education}
            </Descriptions.Item>
            <Descriptions.Item label="信仰">{user.religion}</Descriptions.Item>
            <Descriptions.Item label="是否訂閱月刊">
              {user.order_newsletter ? '是' : '否'}
            </Descriptions.Item>
          </Descriptions>
          <Title level={4} style={{ marginTop: '20px' }}>
            其他資料
          </Title>
          <Divider></Divider>
          <Descriptions
            column={{ xs: 1, sm: 1, md: 2, xl: 3 }}
            style={{ marginBottom: '20px' }}
          >
            <Descriptions.Item label="團隊">{user.team}</Descriptions.Item>
            <Descriptions.Item label="所屬區辦">
              {user.office}
            </Descriptions.Item>
            <Descriptions.Item label="身份">
              {user.identities}
            </Descriptions.Item>
            <Descriptions.Item label="學習護照號碼">
              {user.student_id}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : null}
    </>
  );
};

export default UserInfo;
