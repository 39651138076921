import { Typography } from 'antd';
import { Stack, Box, Flex, Heading } from '@chakra-ui/react';

const { Text } = Typography;

const TermPage = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} minW={'400px'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>會員服務使用條款</Heading>
        </Stack>
        <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
          <Stack>
            <Text fontSize={'md'}>
              <ul>*資料使用條款</ul>
              <Box mb={3}>
                參加者皆同意主辦單位，公開使用肖像權於媒體露出及活動相關文宣。活動現場無法避免拍攝您之肖像，請您見諒！如您同意，請勾選【同意】並繼續完成後續程序。
              </Box>
              <ul>*個資聲明</ul>
              <Box mb={3}>
                本人己詳閱並同意遵循「個人資料保護法」（簡稱個資法）規範及相關權益。俾取得您同意本公司蒐集、處理及利用您所提供之個人資料。
              </Box>
              <Box mb={3}>
                <ul>一、個人資料之類別：</ul>
                <li>
                  基本資料(包括且不限於：姓名、身分證字號、住址、聯絡方式等）。
                </li>
                <li>
                  教育相關資訊(包括且不限於：就讀學校、學習過程及紀錄等)。
                </li>
                <ul>二、資料保密性與安全性：</ul>
              </Box>
              <Box>
                本公司對於所屬員工存取您的個人資料有嚴格的規範，並符合法規要求，以保護您個人資料的安全。
              </Box>
              <Box mb={3}>
                <ul>三、依個資法第3條規定得行使之權利及方式：</ul>
                <li>
                  您使用本服務後，得向本公司請求查詢、閱覽、更正及刪除所留存之資料，倘您前述請求，致影響前述目的之執行，本公司得限制或終止您的會員資格，且本公司就您的查詢、閱覽或複製得請求支付必要成本費用。
                </li>
                <li>
                  若您拒絕提供使用本服務所必須之資料，將可能導致無法使用本服務。
                </li>
              </Box>
              <Box>
                備註:客服電話02-2908-0280或電子郵件聯繫，請於上班時間週一至週五上午09:00至下午17:00洽詢。(例假日除外)
              </Box>
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default TermPage;
