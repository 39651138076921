import { Heading, Stack } from '@chakra-ui/react';
import { Button, Divider, Form } from 'antd';
import moment from 'moment';
import { invoiceOptions } from '../../constants';
import InvoiceFormItem from './InvoiceFormItem';
import PriceFormItem from './PriceFormItem';

const RegistrationForm = ({ session, user, setRegistration, nextStep }) => {
  const [form] = Form.useForm();

  const onFinish = values => {
    const price_id = values.price_id ? values.price_id : 0;
    const data = {
      ...values,
      price_id: price_id,
      create_date: moment().format(),
      course_session_name: session.course_name + session.name,
      session_id: session.id,
      user_id: user.id,
    };
    setRegistration(data);
    nextStep();
  };

  return (
    <Stack align={'center'}>
      <Form
        form={form}
        layout={'vertical'}
        initialValues={{
          price_id: undefined,
          fee: 0,
          invoice: invoiceOptions[0],
        }}
        onFinish={onFinish}
      >
        <Heading size="md" mb={5}>
          {session.course_name}
          {session.name}
        </Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: session.registration_info,
          }}
        ></div>
        <Divider />
        <PriceFormItem user={user} options={session.prices} />
        {session.prices.length > 0 ? <InvoiceFormItem /> : null}
        <Button key="submit" htmlType="submit" type="primary" block>
          確認
        </Button>
      </Form>
    </Stack>
  );
};

export default RegistrationForm;
