import { Box, Stack } from '@chakra-ui/react';
import { Button, Descriptions } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paymentType } from '../../constants';
import EcpayButton from '../EcpayButton';

const RegistrationPayForm = ({
  session,
  registration,
  createFreeRegistration,
  createOfflineRegistration,
  createEcpayRegistration,
}) => {
  const history = useHistory();
  const extra = JSON.parse(registration.extra);
  const [loading, setLoading] = useState(false);

  const onClickFree = () => {
    setLoading(true);
    createFreeRegistration
      .mutateAsync({
        data: registration,
      })
      .then(() => {
        setLoading(false);
        history.push('/profile/registration');
      });
  };

  const onClickOffline = () => {
    setLoading(true);
    createOfflineRegistration
      .mutateAsync({
        data: registration,
        priceId: registration.price_id,
      })
      .then(() => {
        setLoading(false);
        history.push('/profile/registration');
      });
  };
  const showYesOrNo = value => {
    if (value === true) {
      return '是';
    } else if (value === false) {
      return '否';
    } else {
      return value;
    }
  };

  return (
    <>
      {registration ? (
        <>
          <Descriptions column={1}>
            <Descriptions.Item label="課程場次">
              {registration.course_session_name}
            </Descriptions.Item>
            {extra.map(i => (
              <Descriptions.Item label={i.name}>
                {showYesOrNo(i.value)}
              </Descriptions.Item>
            ))}
            {session.prices.length === 0 ? null : (
              <>
                <Descriptions.Item label="發票處理方式">
                  {registration.invoice}
                </Descriptions.Item>
                <Descriptions.Item label="統一編號">
                  {registration.invoice_name}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="備註">
              {registration.note}
            </Descriptions.Item>
            <Descriptions.Item label="費用">
              {session.prices.length === 0
                ? '免費'
                : session.prices.filter(i => i.id === registration.price_id)[0]
                    .amount}
            </Descriptions.Item>
          </Descriptions>
          {session.prices.length === 0 ? (
            <Box align={'center'}>
              <Button onClick={onClickFree} loading={loading}>
                報名
              </Button>
            </Box>
          ) : (
            <Box align={'center'}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justifyContent={'center'}
              >
                <EcpayButton
                  record={registration}
                  createRecord={createEcpayRegistration}
                  paymentType={paymentType.credit_card}
                  paymentTypeName={'信用卡'}
                  priceId={registration.price_id}
                />
                <EcpayButton
                  record={registration}
                  createRecord={createEcpayRegistration}
                  paymentType={paymentType.web_atm}
                  paymentTypeName={'Web ATM'}
                  priceId={registration.price_id}
                />
                <EcpayButton
                  record={registration}
                  createRecord={createEcpayRegistration}
                  paymentType={paymentType.cvs}
                  paymentTypeName={'超商代碼'}
                  priceId={registration.price_id}
                />
                <EcpayButton
                  record={registration}
                  createRecord={createEcpayRegistration}
                  paymentType={paymentType.atm}
                  paymentTypeName={'ATM轉帳'}
                  priceId={registration.price_id}
                />
                <Button onClick={onClickOffline} loading={loading}>
                  特殊身份
                </Button>
              </Stack>
            </Box>
          )}
        </>
      ) : null}
    </>
  );
};

export default RegistrationPayForm;
