import { Cascader, Form, Input } from 'antd';
import { cityOptions, siteOptions } from '../address';

const AddressFormItem = ({ label, name, help = '', required = false }) => {
  const options = cityOptions.map(i => {
    return {
      label: i,
      value: i,
      children: siteOptions[i].map(j => {
        return { label: j['district'], value: j['zip'] + j['district'] };
      }),
    };
  });

  return (
    <Form.Item label={label} required={required} help={help}>
      <Input.Group compact>
        <Form.Item
          name={[name, 'city']}
          noStyle
          rules={[
            {
              required: required ? true : false,
              message: '請選擇縣市',
            },
          ]}
        >
          <Cascader
            options={options}
            placeholder="縣市"
            style={{ width: '30%' }}
          />
        </Form.Item>
        <Form.Item
          name={[name, 'street']}
          noStyle
          rules={[
            {
              required: required ? true : false,
              message: '請填寫路名',
            },
          ]}
        >
          <Input style={{ width: '70%' }} placeholder="路名" />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

export default AddressFormItem;
