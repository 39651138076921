import { Button } from '@chakra-ui/react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { getDisplayAddress } from '../../utils';

const { Text } = Typography;

const SessionTable = ({ sessions }) => {
  let columns = [
    { key: 'name', dataIndex: 'name', title: '場次名稱' },
    {
      key: 'discount_end_date',
      title: '優惠截止日期',
      dataIndex: 'discount_end_date',
    },
    { key: 'course_date', title: '上課日期', dataIndex: 'course_date' },
    {
      key: 'address',
      title: '地址',
      render: (text, record) => (
        <div>
          {getDisplayAddress(
            record.address_city,
            record.address_site,
            record.address_street
          )}
        </div>
      ),
    },
    { key: 'place', dataIndex: 'place', title: '地點' },
    { key: 'contact', dataIndex: 'contact', title: '聯絡方式' },
    {
      key: 'max_count',
      title: '報名人數限制',
      render: (text, record) => (
        <>
          <Text>{record.max_count ? record.max_count : '無限制'}</Text>
          <Text>
            {record.show_count ? '（目前報名人數：' + record.count + '）' : ''}
          </Text>
        </>
      ),
    },
    {
      key: 'action',
      render: (text, record) => (
        <>
          {record.open ? (
            <Link to={`/registration/create/${record.id}`}>
              <Button colorScheme={'brand'} size="sm">
                報名
              </Button>
            </Link>
          ) : (
            <Text>不開放報名</Text>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: '20px' }}
      columns={columns}
      dataSource={sessions}
      className="mt-3"
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default SessionTable;
