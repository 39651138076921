import { Button, Descriptions, Modal } from 'antd';
import React, { useState } from 'react';
import { sendInvoiceOptions } from '../../constants';

const DonationDetailButton = ({ record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = e => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        詳情
      </Button>
      <Modal
        title={`捐款編號: ${record.id}`}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
        ]}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="捐款項目">
            {record.project_name}
          </Descriptions.Item>
          <Descriptions.Item label="捐款日期">
            {record.create_date}
          </Descriptions.Item>
          <Descriptions.Item label="金額">{record.amount}</Descriptions.Item>
          <Descriptions.Item label="捐款類別">
            {record.donation_type}
          </Descriptions.Item>
          <Descriptions.Item label="收據編號">
            {record.invoice_id}
          </Descriptions.Item>
          <Descriptions.Item label="收據寄發方式">
            {record.send_invoice}
          </Descriptions.Item>
          {record.send_invoice !== sendInvoiceOptions[2] ? (
            <Descriptions.Item label="收據寄發地址">
              {record.send_invoice_address}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="芳名錄">
            {record.show_name}
          </Descriptions.Item>
          <Descriptions.Item label="狀態">{record.status}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default DonationDetailButton;
