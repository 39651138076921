import { Form, Radio, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';

const { Text } = Typography;

const PriceFormItem = ({ user, options }) => {
  const identities = user && user.identities ? user.identities.split('; ') : [];

  const passDeadlineCondition = price => {
    return (
      !price.deadline ||
      (price.deadline &&
        moment(price.deadline).local().add(1, 'days') >= moment())
    );
  };
  const passIdentityCondition = (price, identities) => {
    return price.identity === '無' || identities.includes(price.identity);
  };

  return (
    <Form.Item
      label="報名方案"
      required={true}
      name="price_id"
      rules={[
        {
          required: options.length === 0 ? false : true,
          message: '請選擇',
        },
      ]}
    >
      {options.length === 0 ? (
        <Text>免費</Text>
      ) : (
        <Radio.Group>
          <Space direction="vertical">
            {options.map(price => (
              <React.Fragment key={price.name}>
                {passDeadlineCondition(price) &&
                passIdentityCondition(price, identities) ? (
                  <Radio value={price.id} key={price.name}>
                    {price.name} {price.amount}元
                  </Radio>
                ) : null}
              </React.Fragment>
            ))}
          </Space>
        </Radio.Group>
      )}
    </Form.Item>
  );
};

export default PriceFormItem;
