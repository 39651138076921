import { Heading, Stack } from '@chakra-ui/react';
import { Button, Form, Input, Tabs } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { sendInvoiceOptions } from '../../constants';
import AmountFormItem from './AmountFormItem';
import PeriodFormItem from './PeriodFormItem';
import SendInvoiceFormItem from './SendInvoiceFormItem';
import ShowNameFormItem from './ShowNameFormItem';

const { TabPane } = Tabs;
const { TextArea } = Input;

const DonationForm = ({ token, campaign, setDonation, nextStep }) => {
  const [tab, setTab] = useState('單筆');

  const onChangeTab = key => {
    setTab(key);
  };

  const getAmount = amount =>
    amount.default === '自訂' ? amount.custom : amount.default;

  const getPeriod = period =>
    period.default === '自訂' ? period.custom : period.default;

  const getShowName = showName =>
    showName.default === '本名' ? showName.default : showName.custom;

  const getSendInvoiceAddress = sendInvoice =>
    sendInvoice.choice === sendInvoiceOptions.e_annual ||
    sendInvoice.choice === sendInvoiceOptions.e_annual
      ? ''
      : sendInvoice.address;

  const onFinish = values => {
    const amount =
      tab === '定期'
        ? getAmount(values.periodic_amount)
        : getAmount(values.one_time_amount);
    const period = tab === '定期' ? getPeriod(values.period) : 1;
    const data = {
      create_date: moment().format(),
      campaign_id: campaign.id,
      campaign_name: campaign.name,
      amount: amount,
      donation_type: tab,
      period: period,
      send_invoice: values.send_invoice.choice,
      send_invoice_address: getSendInvoiceAddress(values.send_invoice),
      show_name: getShowName(values.show_name),
      note: values.note,
    };
    setDonation(data);
    nextStep();
  };

  return (
    <Stack align={'center'}>
      <Form
        layout={'vertical'}
        initialValues={{
          one_time_amount: { default: 1000, custom: 2000 },
          periodic_amount: { default: 1000, custom: 2000 },
          period: { default: 24, custom: 99 },
          send_invoice: { choice: sendInvoiceOptions.e_annual, address: '' },
          show_name: { default: '本名', custom: '善心人士' },
          note: '',
        }}
        onFinish={onFinish}
      >
        <Heading size="md" mb={5}>
          {campaign.name}
        </Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: campaign.donation_info,
          }}
        ></div>
        <Tabs defaultActiveKey="單筆" onChange={onChangeTab} centered={true}>
          <TabPane tab="單筆捐款" key="單筆">
            <AmountFormItem
              name="one_time_amount"
              label="金額"
              required={true}
              options={campaign.amount_options}
            />
          </TabPane>
          <TabPane tab="定期定額" key="定期">
            <AmountFormItem
              name="periodic_amount"
              label="金額"
              required={true}
              options={campaign.amount_options}
            />
            <PeriodFormItem
              name="period"
              label="期數"
              required={true}
              options={campaign.period_options}
            />
          </TabPane>
        </Tabs>
        <SendInvoiceFormItem
          name={'send_invoice'}
          requrieAddress={token ? false : true}
        />
        <ShowNameFormItem name={'show_name'} />
        <Form.Item label="備註" name="note">
          <TextArea></TextArea>
        </Form.Item>
        <Button key="submit" htmlType="submit" type="primary" block>
          下一步
        </Button>
      </Form>
    </Stack>
  );
};

export default DonationForm;
