import { Box, Container, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useFaq } from '../hooks/faq';

function FaqPage() {
  const { faqs } = useFaq();

  return (
    <Container maxW={'3xl'}>
      <Stack as={Box} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
        {faqs.map(i => (
          <Box>
            <Text fontSize="2xl" mb="3">
              {i.question}
            </Text>
            <Text fontSize="md">{i.answer}</Text>
          </Box>
        ))}
      </Stack>
    </Container>
  );
}

export default FaqPage;
