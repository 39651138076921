import { Divider, Space, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import { paymentType, status } from '../../constants';
import { useUser } from '../../hooks/user';
import { dateTimeFormat, getSessionFilterOptions } from '../../utils';
import ATMButton from '../ATMButton';
import CVSButton from '../CVSButton';
import RegistrationDetailButton from '../registration/RegistrationDetailButton';

const { Title, Text } = Typography;

const UserRegistrationTable = ({ user }) => {
  const { updateRegistration } = useUser();
  const registrations = user['registrations'];

  const data = registrations.map((d, i) => {
    d.key = i;
    d.create_date = moment(d.create_date).local().format(dateTimeFormat);
    return d;
  });

  const columns = [
    { key: 'id', dataIndex: 'id', title: 'No' },
    { key: 'create_date', dataIndex: 'create_date', title: '報名時間' },
    {
      key: 'session',
      title: '課程場次',
      render: (text, record) => (
        <Text>
          {record.course_name}
          {record.session_name}
        </Text>
      ),
      filters: getSessionFilterOptions(data),
      onFilter: (value, record) =>
        record.course_name + record.session_name === value,
    },
    {
      key: 'fee',
      title: '金額',
      render: (text, record) =>
        record.fee === 0 ? <Tag>免費</Tag> : record.fee,
    },
    {
      key: 'status',
      title: '報名狀態',
      render: (text, record) => {
        let color = 'red';
        switch (record.status) {
          case status.complete:
            color = 'green';
            break;
          case status.waiting_for_check:
            color = 'blue';
            break;
          case status.pending:
            color = 'gold';
            break;
          case status.get_info_success:
            color = 'blue';
            break;
          case status.get_info_fail:
            color = 'red';
            break;
          default:
            color = 'red';
            break;
        }

        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(status).map(d => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    { key: 'manager_note', dataIndex: 'manager_note', title: '通知' },
    {
      key: 'note',
      title: '學員備註',
      render: (text, record) => <div>{record.note ? 'v' : null}</div>,
    },
    {
      key: 'action',
      render: (text, record) => (
        <Space>
          {record.status === status.get_info_success &&
          record.payment_type === paymentType.atm ? (
            <ATMButton title={'報名編號'} record={record} />
          ) : null}
          {record.status === status.get_info_success &&
          record.payment_type === paymentType.cvs ? (
            <CVSButton title={'報名編號'} record={record} />
          ) : null}
          <RegistrationDetailButton
            record={record}
            onUpdate={updateRegistration}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title level={4}>報名資料</Title>
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default UserRegistrationTable;
