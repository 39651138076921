import { Form, InputNumber, Radio, Space } from 'antd';

const PeriodFormItem = ({ label, name, required = false, options = [] }) => {
  return (
    <Form.Item label={label} name={[name, 'default']} required={required}>
      <Radio.Group>
        <Space direction="vertical">
          {options
            .split('; ')
            .map(i => parseInt(i))
            .map(i => (
              <Radio value={i} key={i}>
                {i}
              </Radio>
            ))}
          <Radio value={'自訂'}>
            自訂
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[name].default !== currentValues[name].default
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(name).default === '自訂' ? (
                  <Form.Item
                    name={[name, 'custom']}
                    noStyle
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={99}
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export default PeriodFormItem;
