import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useMe } from '../../hooks/me';
import { useUser } from '../../hooks/user';
import { dateFormat } from '../../utils';
import AddressFormItem from '../AddressFormItem';
import MobileFormItem from '../MobileFormItem';
import OfficialIdFormItem from '../OfficialIdFormItem';

const { Option } = Select;

const DonationInfoConfirmForm = ({ nextStep, donation, setDonation }) => {
  const { user } = useMe();
  const { updateUser } = useUser();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      order_newsletter: true,
      birthdate: user?.birthdate ? moment(user.birthdate) : undefined,
      address: {
        city: [user?.address_city, user?.address_site],
        street: user?.address_street,
      },
    });
  }, [user, form]);

  const onFinish = values => {
    const data = {
      ...values,
      birthdate: values.birthdate.format(dateFormat),
      address_city: values.address.city[0],
      address_site: values.address.city[1],
      address_street: values.address.street,
    };
    setDonation({ ...donation, user_id: user.id });
    updateUser.mutateAsync({ data: data }).then(() => {
      nextStep();
    });
  };

  return (
    <Form layout={'vertical'} form={form} onFinish={onFinish}>
      <Form.Item
        label="姓名"
        name="name"
        rules={[
          {
            required: true,
            message: '請填寫',
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <AddressFormItem label={'通訊地址'} name={'address'} required={true} />
      <MobileFormItem required={true} />
      <OfficialIdFormItem required={false} />
      <Form.Item label="生日" name="birthdate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="性別" name="gender">
        <Select>
          <Option value="男" key="男">
            男生
          </Option>
          <Option value="女" key="女">
            女生
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="是否訂閱月刊"
        name="order_newsletter"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Button
        key="submit"
        htmlType="submit"
        type="primary"
        block
        loading={updateUser.isLoading}
      >
        確認且存檔
      </Button>
    </Form>
  );
};

export default DonationInfoConfirmForm;
