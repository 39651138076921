import { Link, Text } from '@chakra-ui/react';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

const CVSButton = ({ title, record }) => {
  const [visible, setVisible] = useState(false);

  const showModal = e => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        超商繳款
      </Button>
      <Modal
        title={`${title}: ${record.id}`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            關閉
          </Button>,
        ]}
      >
        <Text fontSize={'lg'} mb={5}>
          {record.ecpay.payment_no}
        </Text>
        <Text fontSize={'md'}>
          請於 {record.ecpay.expire_date} 前至超商多媒體機台輸入代碼列印繳費單
          (適用 7-11/全家/萊爾富/OK 超商)<br></br>
          繳費截止時間以繳費單顯示為主
          <Link
            color="brand.600"
            href="https://www.ecpay.com.tw/Service/pay_way_cvpay"
          >
            超商繳款說明
          </Link>
        </Text>
      </Modal>
    </>
  );
};

export default CVSButton;
