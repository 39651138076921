export const getErrorMessage = err => {
  if (err.response) {
    return `${err.response.status} ${err.response.statusText}: ${err.response.data['detail']}`;
  }
  return `${err}`;
};

export const getSessionFilterOptions = arr => {
  return arr
    ? [...new Set(arr.map(d => d['course_name'] + d['session_name']))]
        .sort()
        .map(d => {
          return { text: d, value: d };
        })
    : [];
};

export const isSessionForRegister = session => {
  const open =
    // moment(session.register_start_date) <= moment() &&
    // moment() <= moment(session.register_end_date) &&
    session.open;
  return open;
};

export const getDisplayAddress = (
  address_city,
  address_site,
  address_street
) => {
  if (address_site && address_city && address_street) {
    return (
      address_site.slice(0, 3) +
      address_city +
      address_site.slice(3) +
      address_street
    );
  } else {
    return '';
  }
};

export const isNumberRegx = /\d/;
export const specialCharacterRegx = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
