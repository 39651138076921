import { Divider, Space, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import { paymentType, status } from '../../constants';
import { dateTimeFormat } from '../../utils';
import ATMButton from '../ATMButton';
import CVSButton from '../CVSButton';
import DonationDetailButton from '../donation/DonationDetailButton';

const { Title } = Typography;

const UserDonationTable = ({ user }) => {
  const donations = user['donations'];

  const data = donations.map((d, i) => {
    d.key = i;
    d.create_date = moment(d.create_date).format(dateTimeFormat);
    return d;
  });

  const columns = [
    { key: 'id', dataIndex: 'id', title: 'No' },
    { key: 'create_date', dataIndex: 'create_date', title: '捐款時間' },
    {
      key: 'campaing_name',
      title: '項目',
      dataIndex: 'campaign_name',
    },
    {
      key: 'payment_type',
      title: '付款方式',
      dataIndex: 'payment_type',
    },
    { key: 'amount', dataIndex: 'amount', title: '金額' },
    {
      key: 'status',
      title: '狀態',
      render: (text, record) => {
        let color = 'red';
        switch (record.status) {
          case status.complete:
            color = 'green';
            break;
          case status.waiting_for_check:
            color = 'blue';
            break;
          case status.pending:
            color = 'gold';
            break;
          case status.get_info_success:
            color = 'blue';
            break;
          case status.get_info_fail:
            color = 'red';
            break;
          default:
            color = 'red';
            break;
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(status).map(d => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      key: 'action',
      render: (text, record) => (
        <Space>
          {record.status === status.get_info_success &&
          record.payment_type === paymentType.atm ? (
            <ATMButton title={'捐款編號'} record={record} />
          ) : null}
          {record.status === status.get_info_success &&
          record.payment_type === paymentType.cvs ? (
            <CVSButton title={'捐款編號'} record={record} />
          ) : null}
          <DonationDetailButton record={record} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title level={4}>捐款資料</Title>
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default UserDonationTable;
